import { ListItemText } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateResponseMap, numberFormatter } from '../../../helpers';
import { useMuiPickersLocale } from '../../../hooks';
import { reportPaymentTypeOptionsSelector } from '../../../state';
import { PaymentType, ValidationErrorCause } from '../../../types';

interface ErrorDescriptionProps {
  country: string | undefined;
  error: ValidationErrorCause;
}

const getTenderId = (paymentTypes: PaymentType[], tenderId: number) =>
  paymentTypes.find(i => i.paymentTypeId === tenderId)?.tenderId || tenderId;

const formatAmount = (amount: number, locale: Locale) =>
  amount
    ? numberFormatter({
        value: amount,
        defaultValue: '',
        maximumFractionDigits: 2,
        factor: 1,
        locale,
      })
    : amount;

const formatDate = (date: string | undefined, locale: Locale) =>
  date ? format(dateResponseMap(date) as Date, 'P', { locale }) : date;

const getDifference = (headerAmount: number, amount: number, locale: Locale) => {
  const difference = headerAmount && amount ? headerAmount - amount : 0;
  return formatAmount(difference, locale);
};

const getEconomicDifference = (
  grossSale: number | undefined,
  pettyCashDifference: number | undefined,
  cashWithdrawal: number | undefined,
  extraCash: number | undefined,
  theoreticalBalance: number | undefined,
  locale: Locale
) => {
  const difference =
    grossSale && pettyCashDifference && cashWithdrawal && theoreticalBalance
      ? Number(grossSale) -
        Number(theoreticalBalance) +
        Number(pettyCashDifference) -
        Number(cashWithdrawal)
      : 0;
  return formatAmount(difference, locale);
};

const ErrorDescription = ({ country, error }: ErrorDescriptionProps) => {
  const locale = useMuiPickersLocale();
  const { t, i18n } = useTranslation();
  const { data: paymentTypes } = useSelector(reportPaymentTypeOptionsSelector);
  const { errorType, posNumber, elementType, sequence, code, errorDetails } = error;
  const operationDate = errorDetails?.operation_date as string | undefined;

  const data = {
    pos: posNumber || errorDetails?.pos,
    trx: t(`missing-closing-causes.trx.${elementType}`),
    sequence,
    seqNo: errorDetails?.seq_no,
    invalid_cnpj: errorDetails?.invalid_cnpj,
    operationDate: formatDate(operationDate, locale),
    saleGrossSale: formatAmount(errorDetails?.sale_gross_sale as number, locale),
    itemGrossSale: formatAmount(errorDetails?.item_gross_sale as number, locale),
    saleItemDifference: getDifference(
      errorDetails?.sale_gross_sale as number,
      errorDetails?.item_gross_sale as number,
      locale
    ),
    tenderId: getTenderId(paymentTypes, errorDetails?.tender_id as number),
    tenderGrossSale: formatAmount(errorDetails?.tender_gross_sale as number, locale),
    transferId: errorDetails?.transfer_id,
    saleTenderDifference: getDifference(
      errorDetails?.sale_gross_sale as number,
      errorDetails?.tender_gross_sale as number,
      locale
    ),

    plu: errorDetails?.plu,
    saleType: errorDetails?.sale_type_wh,
    fiscalField: errorDetails?.fiscal_field_2,
    tenderName: errorDetails?.tender_name,
    saleAmount: formatAmount(errorDetails?.sale_amount as number, locale),
    discountId: errorDetails?.discount_id,
    accountingStoreId: errorDetails?.accounting_store_id,
    taxDescription: errorDetails?.tax_description,
    grossSale: formatAmount(errorDetails?.gross_sale as number, locale),
    pettyCashDifference: formatAmount(errorDetails?.petty_cash_difference as number, locale),
    theoreticalBalance: formatAmount(errorDetails?.theoretical_balance as number, locale),
    cashWithdrawal: formatAmount(errorDetails?.cash_withdrawal as number, locale),
    extraCash: formatAmount(errorDetails?.extracash as number, locale),
    economicDifference: getEconomicDifference(
      errorDetails?.gross_sale as number,
      errorDetails?.petty_cash_difference as number,
      errorDetails?.cash_withdrawal as number,
      errorDetails?.extracash as number,
      errorDetails?.theoretical_balance as number,
      locale
    ),
    paymentsAmount: formatAmount(errorDetails?.payments_amount as number, locale),
    registersAmount: formatAmount(errorDetails?.registers_amount as number, locale),
    hoursAmount: formatAmount(errorDetails?.hours_amount as number, locale),
    pmixAmount: formatAmount(errorDetails?.pmix_amount as number, locale),
    invoiceNumber: errorDetails?.invoice_number,
    invoiceType: errorDetails?.invoice_type,
    field: errorDetails?.field,
    expectedLength: errorDetails?.expected_length,
    receivedValue: errorDetails?.received_value,
    closingSeq: errorDetails?.closing_seq,
    message: errorDetails?.message,
    nfceKey: errorDetails?.nfce_key,
  };

  return (
    <ListItemText
      primary={t(
        [
          `missing-closing-causes.causes.${errorType}.${country}.${code}.primary`,
          `missing-closing-causes.causes.${errorType}.${code}.primary`,
        ],
        data
      )}
      secondary={
        i18n.exists(`missing-closing-causes.causes.${errorType}.${code}.secondary`)
          ? t(`missing-closing-causes.causes.${errorType}.${code}.secondary`, data)
          : null
      }
    />
  );
};

export default ErrorDescription;
